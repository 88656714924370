
    import Vue from "vue";
    import Component from "vue-class-component";
    import { StateChanger } from "vue-infinite-loading";
    import ApiButton from "@/components/ApiButton.vue";
    import { required } from "vuelidate/lib/validators";
    import { Validations } from "vuelidate-property-decorators";
    import { Watch } from "vue-property-decorator";
    import * as toastr from "toastr";

    import apiClient from "@/stuff/ApiClient";
    import { ScopeSearchParameters } from "@/model/ScopeSearchParameters";
    import { LookupItem } from "@/model/LookupItem";
    import { Scope, IScope } from "@/model/Scope";
    import utils from "@/stuff/Utils";
    import { TriState } from "@/model/Enums";

    @Component({
        components: { ApiButton }
    })
    export default class Scopes extends Vue {

        //
        // -- properties
        //

        private searchParameters = new ScopeSearchParameters();
        private totalCount = -1;
        private scopeList: Array<IScope> = [];
        private readonly scope = new Scope();

        // computed
        private get dialogueTitle(): string {
            if (!this.scope) return "- - -";
            if (utils.isEmptyId(this.scope.id)) return "New Scope";
            return "Edit " + this.scope.description;
        }

        private get countText(): string {
            return this.totalCount === -1 ? "..." : this.totalCount.toString();
        }

        private get isDeletedSearchOptions(): Array<LookupItem> { return [
            new LookupItem({ id: TriState.UseDefault, description: "All", isArchived: false }),
            new LookupItem({ id: TriState.True, description: "Deleted", isArchived: false }),
            new LookupItem({ id: TriState.False, description: "Not deleted", isArchived: false })
        ];}

        //
        // -- Watchers
        //

        @Watch("searchParameters.isDeleted")
        onIsDeletedChanged(value: number) {
            console.log("Is deleted changed = ", value);
            this.refreshSearch();
        }

        //
        // -- methods
        //

        async edit(scopeData: IScope) {
            this.scope.update(scopeData);
            // reset any validation
            this.$v.$reset();
            // show the dialogue
            this.$bvModal.show("scopeDialogue");
			// refresh from server
            const serverScopeData = await apiClient.get(`api/scope/Load?id=${scopeData.id}`);
            this.scope.update(serverScopeData);
        }

        editNew() {
            utils.resetObject(this.scope);
            this.$v.$reset();
            this.$bvModal.show("scopeDialogue");
        }

        cancel() {
            this.$bvModal.hide("scopeDialogue");
            utils.resetObject(this.scope);
        }

        async save(event: Event) {

            // 'touch' all the fields to activate the validation messages
            this.$v.$touch();
            if (this.$v.scope.$invalid) {
                toastr.info("Please fix the highlighted errors", "Validation errors");
                return;
            }
            const response: { newID: number } =  await apiClient.post("/api/scope/save", this.scope, event);
            if (this.scope.isNew) {
                this.scope.id = response.newID;
            }
            toastr.success("Saved");           
            this.$bvModal.hide("scopeDialogue");
            await this.refreshSearch();
        }

        // 'delete' is a reserved word
        async deleteItem(event: Event) {
            if (this.scope.isNew) return;
            const shouldDelete: boolean = await this.$bvModal.msgBoxConfirm("Do you want to delete '" + this.scope.description + "'?", {
                title: "Delete",
                okVariant: "danger",
                okTitle: "Yes, delete!",
                cancelTitle: "No, leave it",
                hideHeaderClose: true,
                centered: true,
                headerClass: "border-bottom-0",
                footerClass: "border-top-0",
                size: "sm"
            });
            if (!shouldDelete) return;
            await apiClient.post("/api/scope/delete", this.scope, event);
            toastr.warning("Deleted");
            this.$bvModal.hide("scopeDialogue");
            await this.refreshSearch();
        }

        refreshSearch(event?: Event) {
            this.scopeList = [];
            this.searchParameters.pageNumber = 1;
            this.totalCount = -1;
            this.search(event);
        }

        async infiniteLoadingHandler(stateChanger: StateChanger) {
            if (this.scopeList.length >= this.totalCount && this.totalCount > -1) {
                stateChanger.complete();
                return;
            }
            this.searchParameters.pageNumber += 1;
            await this.search();
            if (this.scopeList.length >= this.totalCount) {
                stateChanger.complete();
            }
            else {
                stateChanger.loaded();
            }           
        }

        private async search(event?: Event) {
            const response = await apiClient.post("/Api/Scope/Search", this.searchParameters, event);
            if (this.searchParameters.pageNumber === 1) {
                this.totalCount = response.count;
            }
            this.scopeList.push(...response.list.map((s: IScope) => new Scope(s)));
        }

        @Validations()
        validations() {
            const validations = {
                scope: {} as any // eslint-disable-line @typescript-eslint/no-explicit-any
            };
            validations.scope.description = { required };
            return validations;
        }
    }
